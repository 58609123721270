<template>
    <v-btn icon="mdi-delete" variant="text" @click="deleteActivity()">
    </v-btn>
</template>
  
<script setup>
    const props = defineProps({
        params: Object,
    });

    const deleteActivity = async () => {
        let params = props.params
        params.onActivityDeleteCallback(params.node.rowIndex, params.data?.id);
    };

</script>
  