<script setup>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "@/c3api";
import { agGridformatCurrency, formatCurrency } from "@/utils/dataUtils";
import { useToast } from "vue-toastification";
import ActivityDeleteCellRenderer from "@/components/billings/ActivityDeleteCellRenderer.vue";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { ModuleRegistry } from "@ag-grid-community/core";
import { useThemeStore } from "@/stores/themeStore";
ModuleRegistry.registerModules([ClientSideRowModelModule, RichSelectModule]);

const props = defineProps({
  params: Object,
});

const toast = useToast();
const orderBillAmount = ref(0);
const themeStore = useThemeStore();
const INVOICE_COMPLETE = "complete";

const orderBillRates = ref([]);
const orderBillActivities = ref([]);
const orderBillRateLoaded = ref(false);
const orderBillActivitiesLoaded = ref(false);
const allDataLoaded = ref(false);
const rateNames = ref([]);
const isInvoiceComplete = ref(false);
const activitiesToDelete = ref([]);
const rowsModified = ref(false);
const refreshPage = ref(false);

let billableActivitiesLastSaved;

let orderBillActivitiesLoading = ref(true);
let gridApi;

const onGridReady = (params) => {
  gridApi = params.api;
};

watch(rowsModified, (rowsModified) => {
  props.params.context.disableSubmit.value = rowsModified;
});

const getBillableActivities = async (billId) => {
  const url = `/order_bills/${billId}/order_billable_activities`;
  const response = await c3api.get(url);
  orderBillActivities.value = response.data.data;
  calculateTotal();
  billableActivitiesLastSaved = JSON.parse(JSON.stringify(response.data.data));

  orderBillActivitiesLoading.value = false;
  orderBillActivitiesLoaded.value = true;
  allDataLoaded.value = orderBillRateLoaded.value;
};
getBillableActivities(props.params.data.id);

const getOrderBillRates = async (clientId) => {
  const url = `/clients/${clientId}/rates`;
  const response = await c3api.get(url);
  orderBillRates.value = response.data.data;
  rateNames.value = orderBillRates.value.map((rate) => rate.name).sort();
  orderBillRateLoaded.value = true;
  allDataLoaded.value = orderBillActivitiesLoaded.value;
};
getOrderBillRates(props.params.data.client_id);


const calculateTotal = () => {
  orderBillAmount.value = 0;
  orderBillActivities.value.forEach((orderBill) => {
    let unitCost = orderBill.cost;
    let qty = orderBill.qty;
    orderBillAmount.value+=(unitCost* qty);
  });
}

const getActivityCost = (params) => {
  let data = params.data;
  let qty = data.qty;
  let unitCost = data.cost;
  return qty * unitCost;
};

const handleRateColumn = (params) => {
  let newRow;

  if (params.column.colId == "rateName") {
    refreshPage.value = true;
    if (!params.data.id) {
      newRow = orderBillActivities.value[params.rowIndex];
    } else if (params.data.id) {
      newRow = orderBillActivities.value.find(
        (activity) => activity.id == params.data.id
      );
    }

    let rateDefault = orderBillRates.value.find((rate) => rate.name == params.newValue);

    if (!rateDefault) {
      return;
    }
    newRow.rate_id = rateDefault.id;
    newRow.qty = 0;
    newRow.cost = rateDefault.cost;
    gridApi.setGridOption("rowData", orderBillActivities.value);
  }
};

const onActivityDeleteCallback = (index, activityId) => {
  orderBillActivities.value.splice(index, 1);
  if (activityId) {
    activitiesToDelete.value.push(activityId);
  }
  gridApi.setGridOption("rowData", orderBillActivities.value);
  rowsModified.value = true;
};

const onCellValueChanged = (params) => {
  rowsModified.value = true;
  handleRateColumn(params);

  if (params.column.colId === "qty" || params.column.colId === "cost") {
    refreshPage.value = true;
  }
};

isInvoiceComplete.value = props.params.data.order_invoice
  ? props.params.data.order_invoice.workflow_state === INVOICE_COMPLETE
  : false;

const gridOptions = {
  defaultColDef: {
    flex: 1,
    editable: !isInvoiceComplete.value,
    enableCellChangeFlash: true,
    singleClickEdit: true,
  },
  onCellValueChanged: onCellValueChanged,
  autoSizeStrategy: {
    type: "fitCellContents",
  },
  rowHeight: 70,
};

const colDefs = ref([
  {
    field: "rate.name",
    headerName: "Rate",
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      maxLength: 50,
      values: rateNames,
      searchType: "matchOnly",
      filterList: true,
      allowTyping: true,
      highlightMatch: true,
      valueListMaxHeight: 100,
    },
    minWidth: 170,
    colId: "rateName",
  },
  {
    field: "body",
    headerName: "Summary",
    cellEditor: "agTextCellEditor",
    wrapText: true,
    autoHeight: true,
    minWidth: 230,
    maxWidth: 400,
    cellClass: "summary-column",
  },
  {
    field: "qty",
    headerName: "Qty",
    width: 100,
    type: "rightAligned",
    cellEditor: "agNumberCellEditor",
  },
  {
    field: "cost",
    headerName: "Unit Cost",
    cellEditor: "agNumberCellEditor",
    type: "rightAligned",
    valueFormatter: agGridformatCurrency,
  },
  {
    field: "",
    headerName: "Total Cost",
    cellEditor: "agNumberCellEditor",
    editable: false,
    type: "rightAligned",
    valueGetter: getActivityCost,
    valueFormatter: agGridformatCurrency,
  },
  {
    field: "action",
    headerName: "",
    hide: isInvoiceComplete,
    cellRenderer: ActivityDeleteCellRenderer,
    cellRendererParams: {
      onActivityDeleteCallback: onActivityDeleteCallback,
    },
    width: 135,
    editable: false,
  },
]);

const saveItems = async () => {
  let editPayload = orderBillActivities.value.map((item) => {
    return {
      id: item.id,
      rate_id: item.rate_id,
      qty: item.qty,
      cost: item.cost || 0,
      body: item.body,
    };
  });
  for (const activity of editPayload) {
    if (!activity.rate_id) {
      toast.error("Rate is required");
      return;
    }

    if (!activity.qty) {
      toast.error("Quantity is required");
      return;
    }
  }

  const url = `/order_bills/${props.params.data.id}/order_billable_activities`;
  await c3api
    .post(url, {
      order_billable_activities: editPayload,
      remove_order_billable_activities: activitiesToDelete.value,
    })
    .then((response) => {
      toast.success("Billing activity has been successfully saved.");
      getBillableActivities(props.params.data.id);
      activitiesToDelete.value = [];
      props.params.context.onBillSaveCallback();
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });

  rowsModified.value = false;
};

const addNewItem = () => {
  orderBillActivities.value.unshift({
    id: null,
    rate_id: null,
    qty: null,
    cost: null,
    rate: {
      name: "Select A Rate",
    },
  });
  gridApi.setGridOption("rowData", orderBillActivities.value);
  rowsModified.value = true;
  refreshPage.value = true;
};

const cancelEdit = () => {
  orderBillActivities.value = JSON.parse(JSON.stringify(billableActivitiesLastSaved));
  activitiesToDelete.value = [];

  gridApi.setGridOption("rowData", orderBillActivities.value);
  rowsModified.value = false;
};
</script>

<template>
  <div v-if="allDataLoaded" class="pl-4 pb-3 details-container">
    <v-row class="mt-3 mb-3" v-if="!isInvoiceComplete">
      <v-col cols="3" class="mt-2" >
        <v-btn color="secondary" @click="addNewItem()">Add New Item</v-btn>
      </v-col>
      <v-col cols="4">
        <v-chip class="ma-2" variant="outlined" density="compact" color="teal" size="x-large">Total Amount: ${{ orderBillAmount }}</v-chip>
      </v-col>
      <v-col cols="4" class="mt-2">
        <v-btn
          class="mr-2 pl-4"
          color="success"
          :disabled="!rowsModified"
          @click="saveItems()"
          >Save</v-btn
        >
        <v-btn
          class="pl-4"
          color="tertiary"
          :disabled="!rowsModified"
          @click="cancelEdit()"
          >Cancel</v-btn
        >
      </v-col>
    </v-row>
    <div style="height: 90%">
      <AgGridVue
        style="width: 100%; height: 100%"
        :rowData="orderBillActivities"
        :columnDefs="colDefs"
        :gridOptions="gridOptions"
        :class="themeStore.agTheme"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
  </div>
</template>

<!-- TODO styled scope doesn't seem to be working -->
<style>
.details-container .ag-theme-material {
  --ag-borders: none;
}

.ag-theme-material .ag-list {
  max-height: 300px !important;
}

.details-container {
  overflow: scroll;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.details-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.summary-column {
  padding-top: 10px;
  line-height: 1.6;
}
</style>
