<script setup>
import OrderBillDetailRenderer from "@/components/OrderBillDetailRenderer.vue";

import { ref, onMounted } from "vue";

const props = defineProps({
  params: Object,
});
</script>

<template>
  <div class="text-center">
    <v-dialog v-model="props.params.dialog" transition="dialog-top-transition" fullscreen>
      <v-card>
        <v-toolbar>
          <v-btn icon="mdi-close" @click="props.params.toggleDialog(false)"></v-btn>

          <v-toolbar-title
            >B-{{ props.params.data.id }} / {{ props.params.data.client.name }} /
            {{ props.params.data?.order_number }} .
            {{ props.params.data?.po_number }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
        </v-toolbar>

        <div class="content-body d-flex">
          <div class="details d-flex pl-4 pt-4">
            <span>
              <p class="text-h5">Bill To</p>
              <p>{{ props.params.data.client.name }}</p>
            </span>

            <span>
              <p class="text-h5">PO Number</p>
              <p>{{ props.params.data.po_number }}</p>
            </span>

            <span v-if="props.params.data.order_type.name === 'Outbound'">
              <p class="text-h5">Destination</p>
              <p>{{ props.params.data.order?.ship_name }}</p>
              <p>{{ props.params.data.order?.ship_address }}</p>
              <p>
                {{ props.params.data.order?.ship_city
                }}<span v-if="props.params.data.order?.ship_city">,</span>
                {{ props.params.data.order?.ship_zip }}
              </p>
            </span>

            <span v-if="props.params.data.order_type.name === 'Inbound'">
              <p class="text-h5">Origin</p>
              <p>{{ props.params.data.order?.from_name }}</p>
              <p>{{ props.params.data.order?.from_address }}</p>
              <p>
                {{ props.params.data.order?.from_city
                }}<span v-if="props.params.data.order?.ship_city">,</span>
                {{ props.params.data.order?.from_zip }}
              </p>
            </span>

            <span
              v-if="
                props.params.data.order_type?.name === 'Outbound' ||
                props.params.data.order_type?.name === 'Inbound'
              "
            >
              <p class="text-h5">Carrier</p>
              <p>{{ props.params.data.order?.carrier?.name }}</p>
            </span>

            <span v-if="props.params.data.order_type?.name === 'Outbound'">
              <p class="text-h5">Shipped On</p>
              <p>{{ props.params.data.order?.shipped_at }}</p>
            </span>

            <span v-if="props.params.data.order_type?.name === 'Inbound'">
              <p class="text-h5">Received On</p>
              <p>{{ props.params.data.order?.received_at }}</p>
            </span>

            <span
              v-if="
                props.params.data.order_type?.name === 'Outbound' ||
                props.params.data.order_type?.name === 'Inbound'
              "
            >
              <p class="text-h5">Received Contents</p>
              <p>
                {{
                  props.params.data.order_type.name === "Outbound"
                    ? props.params.data.order?.shipped_contents.join(" , ")
                    : props.params.data.order?.received_contents.join(" , ")
                }}
              </p>
            </span>

            <span v-if="props.params.data.order_type.name === 'Transport'">
              <p class="text-h5">Notes</p>
              <p>{{ props.params?.data?.order?.notes }}</p>
            </span>

            <span v-if="props.params.data.order_type.name === 'Special Delivery'">
              <p class="text-h5">Related SLOs</p>
              <div class="related-slos mt-4">
                <v-list :items="props.params?.data?.order?.related_orders" item-title="po_number" item-value="id"></v-list>
              </div>
            </span>
          </div>

          <v-divider class="ms-3" inset vertical></v-divider>

          <div class="activities-list">
            <OrderBillDetailRenderer :params="props.params" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>

.related-slos {
    max-height: calc(100vh - 450px);
    overflow-y: auto; 
}

.activities-list {
  width: 100%;
}

.details {
  flex-basis: 20%;
  flex-direction: column;
  gap: 3rem;
  font-size: large;
}

.content-body {
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 5px;
}
</style>
